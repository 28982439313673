import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "halls-rental-nancy-metz"
    }}><strong parentName="h1">{`Halls rental Nancy Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.539877300613497%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQY0wExAc7+AGxVNl9GJFY5FGBBG2VEHWtLJGtIIHhfQYRxWIqAZqSfkqKKaJ59WrKiksi+r/nw3aFxSK+EWevgzPLk0QB5aE1kTi5XOxZcPhlcOxZpSCBgPRd2XkJ5Z094a1GgloanknSdfFe1n4jAsqXx6t2gb0W2h1rz59X07N4AeWxWY1E2YEstbFg/b1g+b1Y4Z0wsfGpVgHlvYFhOeW1fnIVrmn1fqY58d0Y/vrGnwKWS08Cv9O/o8OznAHp3cXNpW29lV3NkU3RnWnpuY6mnpXZhT6OZjJiYjZ2UiIdqU5yHc7Kil6eOf9PNyrSsqLa0sMbAvc/JxwBhVUtxZFu6xs6BfndMOCNSQS+Fe3B3YlCUi4Ojo6CrrKuloJygloipmY2lj37Dw8CQgnN5Y0ualI6ho6bjlZg/eUwLgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/f994e/LocationSalles3.webp 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bcd70/LocationSalles3.webp 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5d8d7/LocationSalles3.webp 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/c4443/LocationSalles3.webp 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5cc32/LocationSalles3.webp 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/7c4f2/LocationSalles3.webp 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/cf3f8/LocationSalles3.png 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bb21a/LocationSalles3.png 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/b996f/LocationSalles3.png 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/ff5cf/LocationSalles3.png 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png",
              "alt": "Wedding Room Rentals Nancy Metz",
              "title": "Wedding Room Rentals Nancy Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Wedding Room Rentals Nancy Metz`}</strong></p>
    <h2 {...{
      "id": "a-wedding-venue-in-lorraine"
    }}>{`A `}<strong parentName="h2">{`wedding`}</strong>{` venue in `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <h3 {...{
      "id": "organize-your-event-between-nancy-and-metz"
    }}>{`Organize your event between `}<strong parentName="h3">{`Nancy`}</strong>{` and `}<strong parentName="h3">{`Metz`}</strong></h3>
    <p>{`The Château de Morey welcomes your receptions in its authentic and warm places. Make your events unique moments.`}</p>
    <p>{`3 rooms: 1 room for 60/65 adults - 1 room for 15/20 children - 1 dance hall A park of more than one hectare, with trees of a hundred years old and singing birds will transport you elsewhere.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Request your Brochure</ButtonInternal>
    <h2 {...{
      "id": "weddings-receptions-and-cocktails-near-nancy"
    }}><strong parentName="h2">{`Weddings`}</strong>{`, `}<strong parentName="h2">{`receptions`}</strong>{` and cocktails near `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "Company seminars nancy and metz",
              "title": "Company seminars nancy and metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Company seminars nancy and metz`}</strong></p>
    <h3 {...{
      "id": "celebrate-your-union-your-birthday-brunch-with-your-family--near-metz-and-nancy"
    }}>{`Celebrate your union? Your birthday? Brunch with your family ? near `}<strong parentName="h3">{`Metz`}</strong>{` and `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`Wonder yourself and your guests by spending an evening at the Château de Morey. The traditional stone walls of our rooms will plunge you into a chic and refined atmosphere. Between Nancy and Metz, your event can take any style, medieval, refined, modern, country, bohemian … Adapt our places according to your tastes. However, it’s always nice to remember that a charming reception place generates less decoration costs.`}</p>
    <p>{`We have been receiving weddings in Lorraine for more than 20 years, so it is with pleasure that we will accompany you during the organization of your event. We have been familiar with this area for years, however we continue to be amazed at all the weddings we receive, thanks to your authenticity.`}</p>
    <p>{`In Meurthe-et-Moselle, transforming your event into a unique souvenir is possible. We have a bucolic park of more than one hectare, which gives space during your receptions. Where you will be able to see your children running between our Lebanese cedars, your relatives toasting while watching the exceptional view of the valley… Enjoy a family moment in a large and warm house that will give you the feeling of receiving your loved ones at home.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/mariage-clef-main-a-nancy/" mdxType="ButtonInternal">Weddings package</ButtonInternal>
    <ButtonInternal to="/location-salles-nancy-metz/mariages/" mdxType="ButtonInternal">Weddings</ButtonInternal>
    <h2 {...{
      "id": "your-professional-events-a-few-minutes-away-from-nancy-and-metz"
    }}>{`Your professional events a few minutes away from `}<strong parentName="h2">{`Nancy`}</strong>{` and `}<strong parentName="h2">{`Metz`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.539877300613497%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQY0wExAc7+AGxVNl9GJFY5FGBBG2VEHWtLJGtIIHhfQYRxWIqAZqSfkqKKaJ59WrKiksi+r/nw3aFxSK+EWevgzPLk0QB5aE1kTi5XOxZcPhlcOxZpSCBgPRd2XkJ5Z094a1GgloanknSdfFe1n4jAsqXx6t2gb0W2h1rz59X07N4AeWxWY1E2YEstbFg/b1g+b1Y4Z0wsfGpVgHlvYFhOeW1fnIVrmn1fqY58d0Y/vrGnwKWS08Cv9O/o8OznAHp3cXNpW29lV3NkU3RnWnpuY6mnpXZhT6OZjJiYjZ2UiIdqU5yHc7Kil6eOf9PNyrSsqLa0sMbAvc/JxwBhVUtxZFu6xs6BfndMOCNSQS+Fe3B3YlCUi4Ojo6CrrKuloJygloipmY2lj37Dw8CQgnN5Y0ualI6ho6bjlZg/eUwLgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/f994e/LocationSalles3.webp 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bcd70/LocationSalles3.webp 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5d8d7/LocationSalles3.webp 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/c4443/LocationSalles3.webp 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5cc32/LocationSalles3.webp 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/7c4f2/LocationSalles3.webp 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/cf3f8/LocationSalles3.png 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bb21a/LocationSalles3.png 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/b996f/LocationSalles3.png 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/ff5cf/LocationSalles3.png 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png",
              "alt": "Wedding Room Rentals Nancy Metz",
              "title": "Wedding Room Rentals Nancy Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Wedding Room Rentals Nancy Metz`}</strong></p>
    <h3 {...{
      "id": "team-building--conference--company-party--residential-seminar-"
    }}>{`Team Building ? Conference ? Company party ? Residential seminar ?`}</h3>
    <p>{`Special corporate packages are offered at the Château de Morey. In Lorraine, take advantage of this place to inform, thank and encourage your staff, your partners…`}</p>
    <p>{`Enjoy a professional moment, in a warm setting between Nancy and Metz. Join the useful to the pleasant, we take care of the organization of your events:`}</p>
    <p>{`Menus to suit all tastes and budgets
Set-ups adapted to your meetings
A list of providers to entertain your teams
From evening cocktails to residential seminars, we welcome you with great pleasure. Our 5 guest rooms allow you to benefit from accommodation if needed during your event.`}</p>
    <p>{`The charm of the Château also combines calm and relaxation with its wooded park of more than one hectare. A comfortable space, in the middle of nature while being close to Nancy and Metz.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Receive your quote</ButtonInternal> 
    <h2 {...{
      "id": "our-events-close-to-nancy"
    }}>{`Our events close to `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <h3 {...{
      "id": "we-also-like-to-party"
    }}>{`We also like to party…`}</h3>
    <p>{`Sharing a good time with you is a pleasure for us. Each year, we organize about ten events. Spend a lively evening between Nancy and Metz.`}</p>
    <p>{`New Year’s Day, Valentine’s Day, Brunch, Dinner in the dark, Dinner show… In Lorraine, enjoy a festive moment, accompanied by your family, friends, companions or your children. Our events appeal to all ages! The large space available at the Château de Morey allows you to spend a warm and friendly moment for all occasions.`}</p>
    <p>{`It is a pleasure to make you discover our partners during these events, the soft voices of our favorite singers, the dishes of our favorite caterers …`}</p>
    <p>{`You can join our community to be informed of each of our events.`}</p>
    <ButtonInternal to="/en/newsletter/" mdxType="ButtonInternal">Join our newsletter</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      